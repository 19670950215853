var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wscn-http404-container" }, [
      _c("div", { staticClass: "wscn-http404" }, [
        _c("div", { staticClass: "pic-404" }, [
          _c("img", {
            staticClass: "pic-404__parent",
            attrs: {
              src: require("src/assets/404_images/404.png"),
              alt: "404",
            },
          }),
          _c("img", {
            staticClass: "pic-404__child left",
            attrs: {
              src: require("src/assets/404_images/404_cloud.png"),
              alt: "404",
            },
          }),
          _c("img", {
            staticClass: "pic-404__child mid",
            attrs: {
              src: require("src/assets/404_images/404_cloud.png"),
              alt: "404",
            },
          }),
          _c("img", {
            staticClass: "pic-404__child right",
            attrs: {
              src: require("src/assets/404_images/404_cloud.png"),
              alt: "404",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }